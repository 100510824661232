import React, { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";

import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";

import "./footer.styles.scss";
import logo from "../../assets/rvg_logo.svg";
import SocialButton from "../SocialButton";

import {
  FaFacebookF,
  FaTwitter,
  FaGithub,
  FaDribbble,
  FaBehance,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function Footer() {
  const { theme } = useContext(ThemeContext);

  const socialLinks = [
    {
      socialId: 0,
      socialIcon: FaFacebookF,
      socialLink: "https://www.facebook.com/rgala98",
      socialText: "Facebook",
      socialBgColor: theme.facebookBlue,
    },
    {
      socialId: 1,
      socialIcon: BsInstagram,
      socialLink: "https://www.instagram.com/rgala98",
      socialText: "Instagram",
      socialBgColor: theme.instagramPink,
    },
    {
      socialId: 2,
      socialIcon: FaTwitter,
      socialLink: "https://www.twitter.com/rgala98",
      socialText: "Twitter",
      socialBgColor: theme.twitterBlue,
    },
    {
      socialId: 3,
      socialIcon: FaGithub,
      socialLink: "https://www.github.com/rgala98",
      socialText: "Github",
      socialBgColor: theme.githubBlack,
    },
    {
      socialId: 4,
      socialIcon: FaLinkedinIn,
      socialLink: "https://www.linkedin.com/in/rgala98",
      socialText: "LinkedIn",
      socialBgColor: theme.linkedInBlue,
    },
    {
      socialId: 5,
      socialIcon: MdEmail,
      socialLink: "mailto:rahul@rvgala.com",
      socialText: "Email",
      socialBgColor: theme.accentColor,
    },
    {
      socialId: 6,
      socialIcon: FaBehance,
      socialLink: "https://www.behance.net/rgala98",
      socialText: "Behance",
      socialBgColor: theme.behanceBlue,
    },
    {
      socialId: 7,
      socialIcon: FaDribbble,
      socialLink: "https://www.dribbble.com/rgala98",
      socialText: "Dribbble",
      socialBgColor: theme.dribbblePink,
    },
  ];
  return (
    <div className="footer-container container-fluid">
      <Fade bottom duration={2000} distance="20px">
        <footer className="footer row">
          <div className="footer__row row">
            <div className="footer__brand">
              <NavLink to="/" className="logo">
                <img src={logo} alt="Rahul Gala" className="logo-img" />
              </NavLink>
            </div>
            <h1>Rahul Gala</h1>
            <div className="footer__social">
              {socialLinks.map((social) => (
                <div className="footer__social--cta" key={social.socialId}>
                  <SocialButton
                    Icon={social.socialIcon}
                    IconLink={social.socialLink}
                    IconText={social.socialText}
                    IconBgColor={social.socialBgColor}
                  ></SocialButton>
                </div>
              ))}
            </div>
          </div>
          <div className="footer__credits row">
            <span>
              &copy;{new Date().getFullYear()} Rahul Gala. All Rights Reserved.
            </span>
          </div>
        </footer>
      </Fade>
    </div>
  );
}

export default Footer;
