import React, { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";

import "./experiencetile.styles.scss";

const ExperienceTile = ({ experience }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="experience-tile-container">
      <div
        className="experience-tile-image"
        style={{
          backgroundImage: `url(${experience.image})`,
          boxShadow: `0px 0px 20px 2px ${theme.accentColor}`,
        }}
      ></div>
      <div className="experience-tile-desc">
        <h2>{experience.designation}</h2>
        <h3>{experience.companyName}</h3>
        <p>{experience.duration}</p>
      </div>
    </div>
  );
};

export default ExperienceTile;
