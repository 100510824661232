import React, { useState, useEffect } from "react";
import ExperienceTile from "../ExperienceTile";

import ssiLogo from "../../assets/ssi-logo.png";
import replonLogo from "../../assets/replon-logo.png";

import "./abouthero.index.scss";

const AboutHero = () => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    setExperiences([
      {
        id: "EXXP3",
        image: ssiLogo,
        companyName: "Smart Sight Innovations",
        designation: "Associate Lead & Architect",
        duration: "Sept 2023 to Present",
      },
      {
        id: "EXXP2",
        image: ssiLogo,
        companyName: "Smart Sight Innovations",
        designation: "Software Engineer",
        duration: "May 2021 to Aug 2023",
      },
      {
        id: "EXXP1",
        image: replonLogo,
        companyName: "Replon Technologies",
        designation: "Co-Founder | Developer | Designer",
        duration: "June 2019 to Dec 2021",
      },
    ]);

    return () => {
      setExperiences([]);
    };
  }, []);

  return (
    <section className="about-hero">
      <div className="about-hero--left">
        <h2>A quick</h2>
        <h3>Overview</h3>
        <p>
          {new Date().getFullYear() - 2019}+ years of experience, 1 early
          startup
        </p>
      </div>
      <div className="about-hero--right">
        {experiences.length > 0
          ? experiences.map((experience) => (
              <ExperienceTile key={experience.id} experience={experience} />
            ))
          : null}
      </div>
    </section>
  );
};

export default AboutHero;
