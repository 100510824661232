import React, { useState, useContext } from "react";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";
import UseAnimations from "react-useanimations";
import menu4 from "react-useanimations/lib/menu4";
import Switch from "react-switch";
import { FaCloudSun } from "react-icons/fa";
import { WiStars } from "react-icons/wi";

import { ThemeContext } from "../../context/themeContext";

import "./header.styles.scss";
import logo from "../../assets/rvg_logo.svg";
import { SETTINGS } from "../../portfolio";

const Header = () => {
  const [headerMenuOpen, setHeaderMenuOpen] = useState(false);

  const { theme, changeTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    const newTheme = theme.name === "light" ? "dark" : "light";
    localStorage.setItem(SETTINGS.localStorageName, newTheme);
    changeTheme(newTheme);
  };

  return (
    <Fade top duration={1000} distance="2rem">
      <div className="header-container">
        <header className="header">
          {/* LOGO */}
          <NavLink to="/" className="logo">
            <img src={logo} alt="Rahul Gala" className="logo-img" />
          </NavLink>
          {/* NAVIGATION */}
          <ul className="menu">
            <li>
              <NavLink
                to="/"
                className={(isActive) =>
                  "menu-home" + (isActive.isActive ? " menu-home-selected" : "")
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={(isActive) =>
                  "menu-about" +
                  (isActive.isActive ? " menu-about-selected" : "")
                }
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                className={(isActive) =>
                  "menu-projects" +
                  (isActive.isActive ? " menu-projects-selected" : "")
                }
              >
                Projects
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/contact"
                className={(isActive) =>
                  "menu-contact" +
                  (isActive.isActive ? " menu-contact-selected" : "")
                }
              >
                Contact
              </NavLink>
            </li> */}
          </ul>
          <div className="header-actions">
            <Switch
              onChange={() => toggleTheme()}
              checked={theme.name === "dark"}
              offColor={"#87CEEB"}
              onColor={"#4600D3"}
              offHandleColor={"#F9D784"}
              onHandleColor={"#FFFFFF"}
              handleDiameter={30}
              width={80}
              height={40}
              uncheckedIcon={<FaCloudSun size={"75%"} color={"#F9D784"} />}
              checkedIcon={<WiStars size={"100%"} color={"#FFFFF"} />}
            />
            <UseAnimations
              animation={menu4}
              reverse={headerMenuOpen}
              size={56}
              strokeColor={theme.accentColor}
              className="menu-icon"
              onClick={() => {
                setHeaderMenuOpen(!headerMenuOpen);
              }}
            />
          </div>
        </header>

        <Fade top collapse cascade when={headerMenuOpen}>
          <ul className="menu-outer">
            <li>
              <NavLink
                to="/"
                className={(isActive) =>
                  "menu-home" + (isActive.isActive ? " menu-home-selected" : "")
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={(isActive) =>
                  "menu-about" +
                  (isActive.isActive ? " menu-about-selected" : "")
                }
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                className={(isActive) =>
                  "menu-projects" +
                  (isActive.isActive ? " menu-project-selected" : "")
                }
              >
                Projects
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/contact"
                className={(isActive) =>
                  "menu-contact" +
                  (isActive.isActive ? " menu-contact-selected" : "")
                }
              >
                Contact
              </NavLink>
            </li> */}
          </ul>
        </Fade>
      </div>
    </Fade>
  );
};

export default Header;
