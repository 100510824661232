import React from "react";

import "./socialbutton.styles.scss";

const SocialButton = ({ Icon, IconLink, IconText, IconBgColor }) => {
  const handleClick = () => {
    window.open(IconLink, "_blank");
  };
  return (
    <div className="icon-button" onClick={handleClick}>
      <div
        className="icon"
        style={{ backgroundColor: IconBgColor ? IconBgColor : "black" }}
      >
        <Icon className="svgIcon"></Icon>
      </div>
      <span style={{ color: IconBgColor }}>{IconText}</span>
    </div>
  );
};

export default SocialButton;
